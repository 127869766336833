import {createContext, ReactNode, useContext, useEffect, useState} from "react";

import {t} from "@lingui/macro";
import {useRouter} from "next/router";
import useSWR from "swr";

import Loader from "components/loader";
import {MAP_MODE} from "components/map/controls/darkmode-btn/dark-mode-btn";
import StaticPage from "components/static-page";
import {Container} from "components/style";
import Theme from "context/theme";
import {TrackerResponse, TrackerState} from "types/types";
import {initDateTimeLocale} from "utils/datetime-utils";
import {apiFetcher, defaultNotFound, httpFetcher} from "utils/utils";
import {extractUuidFromUrl} from "utils/uuid";

// load environment variables once and pass down via components
const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "";
const ROUTING_API = process.env.NEXT_PUBLIC_ROUTING_API || "";
const ACTIVE_UPDATE_INTERVAL = Number(process.env.NEXT_PUBLIC_UPDATE_ACTIVE_TRACKER_INTERVAL) || 5000;
const QUEUED_UPDATE_INTERVAL = Number(process.env.NEXT_PUBLIC_UPDATE_QUEUED_TRACKER_INTERVAL) || 5000;

interface ITrackerContext {
  mapsAPIKey: string;
  routingAPI: string;

  isActive: boolean;
  isLoading: boolean;
  trackerID: string | string[] | undefined;
  data: TrackerResponse | undefined;
  mapMode: MAP_MODE | undefined;
  setMapMode: any;
}

const TrackerContext = createContext<ITrackerContext>({
  mapsAPIKey: "",
  routingAPI: "",

  isActive: false,
  isLoading: false,
  trackerID: "",
  data: undefined,
  mapMode: undefined,
  setMapMode: null,
});

interface TrackerContextProviderProps {
  id: string | string[] | undefined;
  children: ReactNode;
}

const TrackerProvider = ({id, children}: TrackerContextProviderProps) => {
  const router = useRouter()

  const [refresh, setRefresh] = useState(0);
  const [loadingApp, setLoadingApp] = useState(true);
  const [mapMode, setMapMode] = useState<any>();

  const {data, error} = useSWR<TrackerResponse>(id && `trackers/${id}/public/`, httpFetcher(router.locale), {
    onErrorRetry: (error, key, config, revalidate, {retryCount}) => {
      // on error retry 3 time before timing out
      if (retryCount >= 3) return;
      setTimeout(() => revalidate({retryCount}), 5000);
    },
    refreshInterval: refresh,
  });

  const accountId = extractUuidFromUrl(data?.account);

  const {data: theme, error: themeError} = useSWR(accountId && `/api/themes/?account=${accountId}`, apiFetcher);

  useEffect(() => {
    // init date to use language format
    if (data?.language) {
      initDateTimeLocale(data.language).then(() => {
        setLoadingApp(false);
      });
    }
    // If tracker state is active update data else stop periodic updates
    if (data) {
      switch (data.state) {
        case TrackerState.ACTIVE:
          setRefresh(ACTIVE_UPDATE_INTERVAL);
          break;
        case TrackerState.QUEUED:
          setRefresh(QUEUED_UPDATE_INTERVAL);
          break;
        default:
          // leave refresh state as 0
      }
    }
  }, [data, refresh]);

  const redirectNotFound = error?.status || (data && data?.tasks.length === 0);
  const isActive = data?.state === TrackerState.ACTIVE;

  if (redirectNotFound || !id) return defaultNotFound();
  if ((!data && !error) || loadingApp || (!theme && !themeError))
    return <StaticPage icon={<Loader/>} description={t`Loading Tracking details`}/>;

  const contextValue = {
    mapsAPIKey: GOOGLE_API_KEY,
    routingAPI: ROUTING_API,

    isActive: isActive,
    isLoading: loadingApp,
    trackerID: id,
    data: data,
    mapMode: mapMode,
    setMapMode: setMapMode,
  };

  return (
      <Theme theme={theme || {}}>
        <TrackerContext.Provider value={contextValue}>
          <Container>{children}</Container>
        </TrackerContext.Provider>
      </Theme>
  );
};

const useTrackerContext = () => useContext(TrackerContext);
export {
  GOOGLE_API_KEY,
  ROUTING_API,
  ACTIVE_UPDATE_INTERVAL,
  QUEUED_UPDATE_INTERVAL,
  TrackerContext,
  TrackerProvider,
  useTrackerContext,
};
