import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

export const initDateTimeLocale = async (lang: string) => {
  await require(`dayjs/locale/${lang}.js`);
  const localeData = require("dayjs/plugin/localeData");
  const relativeTime = require("dayjs/plugin/relativeTime");

  dayjs.locale(lang);

  dayjs.extend(relativeTime);
  await dayjs.extend(localizedFormat);
  await dayjs.extend(localeData);
};

export const parseDate = (date: string) => {
  if (!date) return dayjs();
  return dayjs(date);
};

export const formatDateTime = (date: string) => {
  if (!date) return null;
  return parseDate(date).format("DD.MM.YYYY HH:mm");
};

export const formatDate = (date: string) => {
  if (!date) return null;
  return parseDate(date).format("YYYY.MM.D");
};

export const formatTime = (date: string) => {
  if (!date) return null;
  return parseDate(date).format("HH:mm");
};

export const relativeTime = (date: number) => {
  if (!date) return null;

  const t = new Date();
  t.setSeconds(t.getSeconds() + date);

  // @ts-ignore
  return dayjs(t).fromNow(true);
};
