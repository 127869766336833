import React from "react";

import { t } from "@lingui/macro";
import { ThemeProvider } from "styled-components";

interface ITheme {
  colors: {
    primary: string;
    secondary: string;
    bodyTextColor: string;
    btnTextColor: string;
    feedbackBtnColor: string;
    infoBoxBodyColor: string;
    infoBoxButtonColor: string;
    infoBoxBackgroundColor: string;
    background: string;
    headerLiner: string;
    ratingsActive: string;
    ratingsInActive: string;
    ticket: string;
    ticketBackground: string;
    taskIconBackground: string;
  };
  layout: {
    borderRadius: string;
    margin: string;
    showScheduledTime: boolean;
  };
  icons: {
    logo: string;
    darkModeLogo: string;
    taskCategory?: string;
    success: string;
    failed: string;
    time: string;
    contact: string;
    location: string;
    notFound: string;
    carrier: string;
  };
  text: {
    notFoundTitle: string;
    notFoundDescription: string;
    ratings: string;
    feedback: string;
    waitingTitle: string;
    waitingPickedTitle: string;
    waitingQueue: string;
  };
  position: {
    logo: "center" | "flex-start" | "flex-end";
  };
}

const Theme = ({ children, theme }: { children: any; theme: ITheme }) => {
  const defaultTheme = {
    colors: {
      primary: "#60fa67",
      secondary: "#9a39fb",
      bodyTextColor: "#ffffff",
      infoBoxBodyColor: "#ffffff",
      infoBoxButtonColor: "#9a39fb",
      infoBoxBackgroundColor: "#2a2f39",
      feedbackBackgroundColor: "#ffffff",
      feedbackColor: "#000000",
      feedbackBtnColor: "#000000",
      infoBoxComments: "1px solid #949494",
      btnTextColor: "#2a2f39",
      headerLiner: "#949494",
      background: "#2a2f39",
      ratingsActive: "#9a39fb",
      ratingsInActive: "#ececec",
      ticket: "#000000",
      ticketBackground: "#ffffff",
      taskIconBackground: "#2A2F39",
    },
    layout: {
      borderRadius: "3px",
      margin: "16px",
      showScheduledTime: true,
    },
    icons: {
      logo: "/icons/default/logo.svg",
      darkModeLogo: "/icons/default/logo.svg",
      favicon: "/icons/default/favicon.png",
      success: "/icons/default/success.svg",
      failed: "/icons/default/failed.svg",
      time: "/icons/default/time.svg",
      contact: "/icons/default/contact.svg",
      location: "/icons/default/location.svg",
      notFound: "/icons/default/notfound.svg",
      carrier: "/icons/default/carrier.svg",
    },
    text: {
      appTitle: t`GSMTasks Tracker`,
      notFoundTitle: t`Nothing found`,
      notFoundDescription: t`It looks like your link has expired or wasn’t copied correctly. `,
      ratings: t`How would you rate your experience?`,
      feedback: t`Delivery`,
      waitingTitle: t`Task is waiting for accept `,
      waitingPickedTitle: t`Task waiting to be picked up and queued`,
      waitingQueue: t`Your task queue number is`,
    },
    position: {
      logo: "flex-start",
    },
  };

  const mergeTheme = Object.assign({}, defaultTheme, theme);

  return <ThemeProvider theme={mergeTheme}>{children}</ThemeProvider>;
};

export default Theme;
