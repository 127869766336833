import { ReactNode } from "react";

import styled from "styled-components";

import Wrapper from "components/wrapper";

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  color: #000000;
`;

const Headings = styled.h1`
  line-height: 0;
  margin-top: 36px;
`;

const Description = styled.p`
  text-align: center;
  max-width: 256px;
`;

interface StaticPageProps {
  icon: ReactNode;
  title?: string;
  description?: string;
}

const StaticPage = ({ icon, title, description }: StaticPageProps) => {
  return (
    <Wrapper>
      <Container>
        {icon}
        {title && <Headings>{title}</Headings>}
        {description && <Description>{description}</Description>}
      </Container>
    </Wrapper>
  );
};
export default StaticPage;
