import { ReactNode } from "react";

import Head from "next/head";
import { ToastContainer } from "react-toastify";
import { useTheme } from "styled-components";

import "react-toastify/dist/ReactToastify.css";

const Wrapper = ({ children }: { children: ReactNode }) => {
  const theme: any = useTheme();

  return (
    <>
      <Head>
        <title>{theme?.text?.appTitle || "GSMTasks Tracker"}</title>
        <meta name="description" content={"Tracking"} />
        <link rel="shortcut icon" href={theme?.icons?.favicon || "/favicon.png"} type="image/x-icon" />
      </Head>
      <main>{children}</main>
      <ToastContainer
        limit={1}
        position="top-center"
        hideProgressBar
        closeButton={false}
        style={{ width: "max-content" }}
      />
    </>
  );
};

export default Wrapper;
