import "styles/globals.css";

import { useEffect } from "react";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { en, et } from "make-plural/plurals";
import { useRouter } from "next/router";

import Wrapper from "components/wrapper";
import { TrackerProvider } from "context/tracker";
import { getLocale } from "utils/utils";

i18n.loadLocaleData("en", { plurals: en });
i18n.loadLocaleData("et", { plurals: et });

const App = ({ Component, pageProps }: { Component: any; pageProps: any }) => {
  const { locale, query } = useRouter();
  const { id } = query;

  useEffect(() => {
    async function load(locale: string) {
      const { messages } = await import(`../locale/${locale}/messages.po`);

      i18n.load(locale, messages);
      i18n.activate(locale);
    }

    load(getLocale(locale));
  }, [locale]);

  return (
    <I18nProvider i18n={i18n as any}>
      <TrackerProvider id={id}>
        <Wrapper>
          <Component {...pageProps} />
        </Wrapper>
      </TrackerProvider>
    </I18nProvider>
  );
};

export default App;
