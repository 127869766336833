const NotfoundIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32" r="31" stroke="#000000" strokeWidth="2" />
      <rect
        x="11.4283"
        y="34.2849"
        width="18.2857"
        height="1.14286"
        rx="0.2"
        transform="rotate(-45 11.4283 34.2849)"
        fill="#60FA67"
      />
      <rect
        x="11.9997"
        y="21.1421"
        width="18.2857"
        height="1.14286"
        rx="0.2"
        transform="rotate(45 11.9997 21.1421)"
        fill="#60FA67"
      />
      <rect
        x="38.8575"
        y="34.2849"
        width="18.2857"
        height="1.14286"
        rx="0.2"
        transform="rotate(-45 38.8575 34.2849)"
        fill="#60FA67"
      />
      <rect
        x="39.4289"
        y="21.1421"
        width="18.2857"
        height="1.14286"
        rx="0.2"
        transform="rotate(45 39.4289 21.1421)"
        fill="#60FA67"
      />
    </svg>
  );
};

export default NotfoundIcon;
