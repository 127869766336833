export const DEFAULT_ZOOM = 14;
export const MAP_MODE_KEY = "tracker.gsmtasks.com.map.mode";

interface Coordinates {
  coordinates: number[];
}

export type LatLngLiteral = {
  lat: number;
  lng: number;
};

export enum TaskState {
  UNASSIGNED = "unassigned",
  ASSIGNED = "assigned",
  ACCEPTED = "accepted",
  TRANSIT = "transit",
  ACTIVE = "active",
  COMPLETED = "completed",
  FAILED = "failed",
  CANCELLED = "cancelled",
}

export enum TrackerState {
  ACTIVE = "active",
  INACTIVE = "inactive",
  QUEUED = "queued",
}

interface Assignee {
  id: string;
  first_name: string;
  last_name: string;
  display_name: string;
  email: string;
  phone: string;
  last_time_location: {
    location: Coordinates;
    heading: number;
    accuracy: number;
  };
}

export interface Task {
  id: string;
  assignee: string;
  contact: {
    name: string;
    company: string;
  };
  assignee_data: Assignee | undefined;
  state: TaskState;
  category: string;
  completed_at: string | null;
  cancelled_at: string | null;
  failed_at: string | undefined | null;
  duration: string;
  scheduled_time: string | null;
  address: {
    formatted_address: string;
    location: Coordinates;
  };
}

interface QueuePositions {
  [key: string]: number;
}

export interface TrackerResponse {
  id: string;
  url: string;
  account: string;
  state: string;
  assignees: Assignee[];
  tasks: Task[];
  logo: string | null;
  active_from?: string | null;
  active_until?: string | null;
  queue_positions: QueuePositions[];
  queued_states: TaskState[];
  active_states?: TaskState[];
  show_driver_info?: boolean;
  show_destination?: boolean;
  show_eta?: boolean;
  show_sms_action?: boolean;
  show_call_action?: boolean;
  show_logo?: boolean;
  show_path?: boolean;
  show_predicted_delivery?: boolean;
  autozoom?: boolean;
  max_zoom_level?: number | null;
  tracking_page_url: string;
  language: string;
  reviews_allowed: boolean;
  reviewed_at?: string | null;
  predicted_delivery: string | null;
  predicted_delivery_at: string | null;
  predicted_delivery_error?: string | null;
}
